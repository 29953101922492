import React, { useState, Fragment } from 'react';
import { Button, } from 'reactstrap';
import { get, isEmpty, orderBy, keyBy, } from 'lodash';
import { format as formatDate, addMonths, startOfDay, endOfDay, } from 'date-fns';
import qs from 'qs';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import Select from 'react-select';
import { useToggle, useCounter, } from 'react-use';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import firebase, { functions } from '../../firebase';
import { yen, ordersToRows } from '../../util';
import { malls, inventoryTypes, logispShippingRequestStatuses, } from '../../shared/config';
import { statuses as orderStatuses, adminFields as orderAdminFields, } from '../../shared/models/order';
import { adminFields as productAdminFields, } from '../../shared/models/product';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';
import ModelFormModal from '../modals/ModelFormModal';
import OrderDetailModal from '../modals/OrderDetailModal';
import ExpanderPage from '../hocs/ExpanderPage';
import ExportButton from '../ExportButton';
import EditButton from '../EditButton';
import ProgressButton from '../ProgressButton';
import DeleteButton from '../DeleteButton';
import DateSelector from '../DateSelector';
import QuerySelector from '../QuerySelector';
import QueryInput from '../QueryInput';

const syncOrder = functions.httpsCallable('syncOrder');
const createAmazonOrderFromOrder = functions.httpsCallable('createAmazonOrderFromOrder');
const createLogispShippingRequestFromOrder = functions.httpsCallable('createLogispShippingRequestFromOrder');
const pullShopOrders = functions.httpsCallable('pullShopOrders', { timeout: 550000 });
const pullAmazonOrders = functions.httpsCallable('pullAmazonOrders', { timeout: 550000 });
const pullAmazonOrder = functions.httpsCallable('pullAmazonOrder', { timeout: 550000 });
const pullLogispShippingRequests = functions.httpsCallable('pullLogispShippingRequests', { timeout: 550000 });
const db = firebase.firestore();
const yahooOrdersRef = db.collection('yahooOrders');
const productsRef = db.collectionGroup('products');
const ordersRef = db.collection('orders');
const tenantsRef = db.collection('tenants');
const { entries, } = Object;
const statusOptions = entries(orderStatuses).map(([k, v]) => ({ value: k, label: v, }));
const amazonStatusOptions = ['RECEIVED', 'FAILED', 'PLANNING', 'PROCESSING', 'COMPLETE', 'CANCELLED'].map(_ => ({ value: _, label: _, }));
const searchTypes = {
  // id: { label: '注文ID', field: firebase.firestore.FieldPath.documentId(), query: _ => _.where(},
  id: { label: '注文ID', query: (ref, keyword) => ref.where(firebase.firestore.FieldPath.documentId(), '>=', keyword).where(firebase.firestore.FieldPath.documentId(), '<=', keyword + '\uf8ff'), },
  itemIds: { label: '商品ID', query: (ref, keyword) => ref.where('itemIds', 'array-contains', keyword), },
};
const searchTypeOptions = entries(searchTypes).map(([k, _]) => ({ label: _.label, value: k }));

export default ExpanderPage(function ExpanderDashboard (props) {
  const { user, expander, history, location, location: { search } } = props;
  const [targetOrder, setTargetOrder] = useState(null);
  const [version, { inc: updateVersion }] = useCounter(0);
  const [showsOrderDetailModal, toggleOrderDetailModal] = useToggle(false);
  const {
    startOn: startOnString = formatDate(addMonths(new Date(), -1), 'yyyy-MM-dd'),
    endOn: endOnString = formatDate(new Date(), 'yyyy-MM-dd'),
    statuses: statusesForFilter,
    amazonStatuses: amazonStatusesForFilter,
    shopIds: shopIdsForFilter,
    searchType,
    keyword,
  } = useQueryParams();
  const startOn = new Date(startOnString);
  const endOn = new Date(endOnString);
  const [selectedShopIds, setSelectedShopIds] = useState([]);
  const tenants = useCollectionSubscription(tenantsRef);
  const tenantsById = keyBy(tenants, 'id');
  const shops = useCollectionSubscription(expander.ref.collection('shops').orderBy('createdAt'), [expander]);
  const shopsById = keyBy(shops, 'id');
  const shopOptions = shops.map(_ => ({ label: _.name, value: _.id }));
  const isSearching = !isEmpty(searchType);

  // NOTE: filter
  let filteredOrdersRef = ordersRef.where('expanderId', '==', expander.id);
  if(isSearching) {
    if(isEmpty(keyword)) {
      filteredOrdersRef = filteredOrdersRef.where('dummy', '==', 'dummy');
    } else {
      const { query, } = searchTypes[searchType];
      filteredOrdersRef = query(filteredOrdersRef, keyword);
    }
  } else {
    filteredOrdersRef = filteredOrdersRef.where('orderedAt', '>=', startOfDay(startOn)).where('orderedAt', '<=', endOfDay(new Date(endOn))).orderBy('orderedAt', 'desc');
    if(!isEmpty(statusesForFilter)) {
      const statuses = statusesForFilter.split(',');
      filteredOrdersRef = filteredOrdersRef.where('status', 'in', statuses);
    }
  }
  const orders = useCollectionSubscription(filteredOrdersRef, [startOnString, endOnString, statusesForFilter, amazonStatusesForFilter, searchType, keyword]);
  let filteredOrders = orders;
  if(!isSearching && !isEmpty(shopIdsForFilter)) {
    const shopIds = shopIdsForFilter.split(',');
    filteredOrders = filteredOrders.filter(_ => shopIds.includes(_.shopId));
  }
  if(!isSearching && !isEmpty(amazonStatusesForFilter)) {
    const amazonStatuses = amazonStatusesForFilter.split(',');
    filteredOrders = filteredOrders.filter(_ => amazonStatuses.includes(_.amazonStatus));
  }
  const products = useDocumentsFetch(orders.flatMap(o => o.items.map(_ => tenantsRef.doc(o.tenantId).collection('products').doc(_.itemId))), [orders, version]);
  const productsById = keyBy(products, 'id');

  const rows = ordersToRows(filteredOrders, productsById, tenantsById, shopsById);
  const rowsForExport = _ => rows.map((row) => {
    const {
      items,
      order: { id, sourceOrderId, orderedAt, totalPrice, mall, shopId, amazonStatus, logispStatus, processedAt, adminNote, recreatedAmazonOrderId, amazonOrderErrorMessage, status, },
      tenant,
      shop,
      shipments,
      shippedAt,
      shipmentCarrier,
      trackingNumber,
    } = row;
    return items.map((item) => {
      const { itemId, quantity, unitPrice, netPrice, sku, title, imageUrl, itemUrl, } = item;
      return {
        sourceOrderId,
        orderId: id,
        shopId,
        shopName: shop?.name,
        orderedAt: formatDate(orderedAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        tenantName: tenant.name,
        sku,
        itemId,
        itemTitle: title,
        itemUrl,
        imageUrl,
        netPrice,
        unitPrice,
        quantity,
        totalPrice,
        amazonStatus,
        amazonOrderErrorMessage,
        logispStatus: logispShippingRequestStatuses[logispStatus]?.label,
        shippedAt,
        shipmentCarrier,
        trackingNumber,
        processedAt: processedAt && formatDate(processedAt.toDate(), 'yyyy/MM/dd HH:mm'),
        mall,
        status: orderStatuses[status],
        recreatedAmazonOrderId,
        adminNote,
      };
    });
  }).flat();
  const toggleStatus = async (order, nextStatus) => {
    if(!window.confirm(`本当に${({ processed: '処理済みにし', cancelled: 'キャンセル済みにし', initial: '戻し' })[nextStatus]}ますか？`)) return;

    try {
      await order.ref.update({ status: nextStatus, updatedAt: new Date(), processedAt: ({ initial: null, processed: new Date(), cancelled: null, })[nextStatus], });
      toast.success('更新しました');
    } catch(e) {
      toast.error('失敗しました');
      console.error(e);
    }
  };
  const onClickPullShopOrders = async () => {
    if(!window.confirm(`最新の店舗注文を取得します。`)) return;

    try {
      await Promise.all(selectedShopIds.map(async (shopId) => {
        const res = await pullShopOrders({ expanderId: expander.id, shopId });
        console.log(res);
        if(res.data?.error) {
          throw new Error(res.data.error);
        }
      }));
      toast.success('取得しました');
    } catch(e) {
      toast.error(`失敗しました (${e.message})`);
    }
  };
  const onClickPullLogispShippingRequests = async () => {
    if(!window.confirm(`最新のLOGISP出荷依頼を取得します。`)) return;

    try {
      const res = await pullLogispShippingRequests({ expanderId: expander.id, });
      console.log(res);
      if(res.data?.error) {
        throw new Error(res.data.error);
      }
      toast.success('取得しました');
    } catch(e) {
      toast.error(`失敗しました (${e.message})`);
    }
  };

  return (
    <div className="admin-orders container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>注文管理</h4>
        </div>
        <div className="d-flex justify-content-start mb-3">
          <div className="card" style={{ opacity: isSearching && 0.4 }}>
            <div className="card-header">
              絞り込み
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center flex-wrap">
                <DateSelector paramName="startOn" label="開始日" defaultValue={addMonths(new Date(), -1)} disabled={isSearching} />
                <DateSelector className="ml-2" paramName="endOn" label="終了日" defaultValue={new Date()} disabled={isSearching} invalid={startOn > endOn} />
              </div>
              <div className="mb-3 d-flex align-items-center">
                <div className="mt-2 d-flex align-items-center flex-wrap">
                  <QuerySelector paramName="amazonStatuses" isMulti options={amazonStatusOptions} label="出荷状態で絞込み" disabled={isSearching} {...{ history, location }} />
                  <QuerySelector className="ml-2" paramName="statuses" isMulti options={statusOptions} label="ステータスで絞込み" disabled={isSearching} {...{ history, location }} />
                  <QuerySelector className="ml-2" paramName="shopIds" isMulti options={shopOptions} label="店舗で絞込み" disabled={isSearching} {...{ history, location }} />
                </div>
              </div>
            </div>
          </div>
          <div className="card ml-3">
            <div className="card-header">
              検索
            </div>
            <div className="card-body">
              <div className="mb-3 d-flex align-items-center">
                <div className="mt-2 d-flex align-items-center flex-wrap">
                  <QuerySelector paramName="searchType" options={searchTypeOptions} label="検索対象" {...{ history, location }} />
                  <QueryInput className="ml-2" paramName="keyword" label="検索キーワード" {...{ history, location }} disabled={!isSearching} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-2 align-items-end gap-1">
          <div className="p-2 card">
            <div className="d-flex align-items-end gap-1">
              <div style={{ width: 300 }}>
                <Select
                  isMulti
                  options={shopOptions}
                  value={shopOptions.filter(_ => selectedShopIds.includes(_.value))}
                  onChange={_ => setSelectedShopIds((_ || []).map(_ => _.value))}
                  className="w-100"
                />
              </div>
              <ProgressButton process={onClickPullShopOrders} disabled={selectedShopIds.length === 0}>
                最新の店舗注文を取得
              </ProgressButton>
            </div>
          </div>
          <OrderSyncButton label="最新のAmazon出荷指示を取得" pull={pullAmazonOrders} expander={expander} />
          <ProgressButton process={onClickPullLogispShippingRequests}>
            最新のLOGISP出荷依頼を取得
          </ProgressButton>
          <ExportButton fileName="orders.csv" rows={rowsForExport} />
        </div>
        <div className="mt-4 overflow-scroll position-relative" style={{ zIndex: 0 }}>
          {
            rows.length > 0 ? (
              <table className="table sticky-table table-bordered">
                <thead className="thead-light text-center">
                  <tr className="text-nowrap">
                    <th>モール注文ID</th>
                    <th>注文ID</th>
                    <th>店舗</th>
                    <th>注文日時</th>
                    <th style={{ minWidth: 130 }}>会社ID</th>
                    <th style={{ minWidth: 130 }}>会社</th>
                    <th>画像</th>
                    <th style={{ minWidth: 100 }}>SKU</th>
                    <th style={{ minWidth: 130 }}>商品ID</th>
                    <th style={{ minWidth: 250 }}>商品名</th>
                    <th style={{ minWidth: 250 }}>商品メモ</th>
                    <th>配送元</th>
                    <th>卸値単価</th>
                    <th>単価</th>
                    <th>個数</th>
                    <th>合計金額</th>
                    <th>FBA出荷状態</th>
                    <th>FBA出荷指示エラー</th>
                    <th>LOGISPステータス</th>
                    <th>出荷日時</th>
                    <th>配送会社</th>
                    <th>伝票番号</th>
                    <th>処理日時</th>
                    <th>モール</th>
                    <th>ステータス</th>
                    <th>再出荷指示ID</th>
                    <th>注文memo</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    rows.map((row) => {
                      const {
                        items,
                        order,
                        order: { id, ref, sourceOrderId, orderedAt, totalPrice, mall, amazonStatus, amazonOrderErrorMessage, status, processedAt, adminNote, recreatedAmazonOrderId, inventoryType = 'fba', logispStatus, logispShippingRequest, },
                        tenant,
                        shop,
                        shipments,
                        shippedAt,
                        shipmentCarrier,
                        trackingNumber,
                      } = row;
                      const onClickShowDetail = () => {
                        setTargetOrder(order);
                        toggleOrderDetailModal(true);
                      };
                      const onClickPullAmazonOrder = async (amazonOrderId) => {
                        try {
                          const res = await pullAmazonOrder({ orderId: id, amazonOrderId, });
                          toast.success('出荷指示を取得しました');
                        } catch(e) {
                          console.error(e);
                          toast.error('失敗しました');
                        }
                      };
                      const onClickCreateAmazonOrder = async (isRecreation, shippingSpeedCategory) => {
                        if(!window.confirm(`${shippingSpeedCategory === 'Standard' ? '通常配送で' : ''}出荷指示を${isRecreation ? '再' : ''}作成しますか？`)) return;

                        try {
                          if(isRecreation) {
                            if(order.recreatedAmazonOrderId == null) {
                              await ref.update({ recreatedAmazonOrderId: sourceOrderId + '-r', });
                            } else if('shouldRecreateAmazonOrderBy14Dates' in order) {
                              await ref.update({ recreatedAmazonOrderId: sourceOrderId + '-rr', });
                            }
                          }
                          const { data } = await createAmazonOrderFromOrder({ orderId: id, ...(shippingSpeedCategory && { shippingSpeedCategory }), });
                          if(get(data, 'error')) {
                            toast.error(`作成できませんでした。 ${data.error}`);
                          } else {
                            toast.success('作成しました');
                          }
                        } catch(e) {
                          console.error(e);
                          toast.error('失敗しました');
                        }
                      };
                      const onClickCreateLogispShippingRequest = async () => {
                        if(!window.confirm(`LOGISP出荷依頼を作成しますか？`)) return;

                        try {
                          const { data } = await createLogispShippingRequestFromOrder({ orderId: id, });
                          if(get(data, 'error')) {
                            toast.error(`作成できませんでした。 ${data.error}`);
                          } else {
                            toast.success('作成しました');
                          }
                        } catch(e) {
                          console.error(e);
                          toast.error('失敗しました');
                        }
                      };
                      const onClickSyncOrder = async () => {
                        if(!window.confirm('現在のH+のモール注文情報（のうち住所のみ）を出荷指示に反映します。よろしいですか？')) return;

                        try {
                          await syncOrder({ orderId: id });
                          toast.success('更新しました');
                        } catch(e) {
                          console.error(e);
                          toast.error(e.message);
                        }
                      };
                      const isHold = get(order, 'FulfillmentOrder.FulfillmentAction') === 'Hold';
                      const isShip = order.FulfillmentOrder?.FulfillmentAction === 'Ship' && !order.FulfillmentOrder?.FulfillmentOrderStatus.toLowerCase().startsWith('cancel');
                      const onDeleted = async () => {
                        if(order.mall === 'yahoo') {
                          await yahooOrdersRef.doc(sourceOrderId).delete();
                        }
                      };

                      return (
                        <Fragment key={id}>
                          {
                            items.map((item, i) => {
                              const { product, sku, quantity, title, itemId, unitPrice, netPrice, imageUrl, itemUrl, } = item;
                              const mallUrl = {
                                rakuten: `https://order-rp.rms.rakuten.co.jp/order-rb/individual-order-detail-sc/init?orderNumber=${sourceOrderId}`,
                                yahoo: `https://pro.store.yahoo.co.jp/pro.${shop?.yahooStoreAccountName}/order/manage/detail/${sourceOrderId}`,
                              }[shop?.mall];

                              return (
                                <tr key={itemId}>
                                  {
                                    i === 0 && (
                                      <Fragment>
                                        <td rowSpan={items.length}>
                                          <a href={mallUrl} target="_blank">
                                            {sourceOrderId}
                                          </a>
                                        </td>
                                        <td rowSpan={items.length}>
                                          {id}
                                          {
                                            user.dev && (
                                              <a className="d-block" href={`https://console.firebase.google.com/u/0/project/hanro-plus/firestore/data/~2Forders~2F${id}`} target="_blank">
                                                F
                                              </a>
                                            )
                                          }
                                        </td>
                                        <td rowSpan={items.length}>
                                          {shop?.name || '(存在しない店舗)'}
                                        </td>
                                        <td rowSpan={items.length}>
                                          {formatDate(orderedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                                        </td>
                                        <td rowSpan={items.length}>
                                          {tenant?.id}
                                        </td>
                                        <td rowSpan={items.length}>
                                          {user.admin ? <Link to={`/admin/tenants/${tenant?.id}`} target="_blank">{tenant?.name}<span className="ml-1 fas fa-external-link-alt" /></Link> : tenant?.name}
                                        </td>
                                      </Fragment>
                                    )
                                  }
                                  <td>
                                    <img src={imageUrl} style={{ maxWidth: 80 }} />
                                  </td>
                                  <td className="text-break">
                                    {sku}
                                  </td>
                                  <td className="text-break">
                                    <Link to={`/ex/${expander.id}/products?${qs.stringify({ keyword: itemId, searchType: 'productId', showsAll: '1' })}`} target="_blank">
                                      {itemId}
                                    </Link>
                                    {
                                      user.dev && (
                                        <a className="d-block" href={`https://console.firebase.google.com/u/0/project/hanro-plus/firestore/data/~2Ftenants~2F${tenant.id}~2Fproducts~2F${itemId}`} target="_blank">
                                          F
                                        </a>
                                      )
                                    }
                                  </td>
                                  <td className="text-break">
                                    {
                                      title != null ? (
                                        <a href={itemUrl} target="_blank">
                                          {title}
                                        </a>
                                      ) : (
                                        <span className="small text-muted">[削除された商品]</span>
                                      )
                                    }
                                  </td>
                                  <td>
                                    {
                                      product?.createdAt != null && (
                                        <div className="d-flex justify-content-between">
                                          <div className="flex-fill" style={{ whiteSpace: 'pre-line', }}>
                                            {product.adminNote}
                                          </div>
                                          <EditButton itemRef={product.ref} FormModal={ModelFormModal} formProps={{ title: '商品メモ', fields: productAdminFields(), }} onFinish={_ => updateVersion()} />
                                        </div>
                                      )
                                    }
                                  </td>
                                  <td>
                                    {inventoryTypes[inventoryType]?.label}
                                  </td>
                                  <td className="text-right">
                                    {yen(netPrice)}
                                  </td>
                                  <td className="text-right">
                                    {yen(unitPrice)}
                                  </td>
                                  <td className="text-right">
                                    {numeral(quantity).format('0,0')}
                                  </td>
                                  {
                                    i === 0 && (
                                      <Fragment>
                                        <td className="text-right" rowSpan={items.length}>
                                          {yen(totalPrice)}
                                        </td>
                                        <td rowSpan={items.length}>
                                          {amazonStatus}
                                          {amazonStatus === 'FAILED' && (
                                            <ProgressButton size="sm" process={_ => ref.update({ amazonStatus: 'RECEIVED' })}>
                                              RECEIVEDにする
                                            </ProgressButton>
                                          )}
                                        </td>
                                        <td rowSpan={items.length}>
                                          {amazonOrderErrorMessage}
                                        </td>
                                        <td rowSpan={items.length}>
                                          {logispShippingRequestStatuses[logispStatus]?.label}
                                        </td>
                                        <td>
                                          {shippedAt}
                                        </td>
                                        <td>
                                          {shipmentCarrier}
                                        </td>
                                        <td>
                                          {trackingNumber}
                                        </td>
                                        <td>
                                          {processedAt && formatDate(processedAt.toDate(), 'yyyy/MM/dd HH:mm')}
                                        </td>
                                        <td className="text-nowrap" rowSpan={items.length}>
                                          {mall}
                                        </td>
                                        <td className="text-nowrap" rowSpan={items.length}>
                                          {orderStatuses[status]}
                                        </td>
                                        <td className="text-nowrap" rowSpan={items.length}>
                                          {recreatedAmazonOrderId}
                                        </td>
                                        <td rowSpan={items.length} style={{ whiteSpace: 'pre-line', }}>
                                          {adminNote}
                                        </td>
                                        <td className="text-nowrap text-right" rowSpan={items.length}>
                                          {
                                            status === 'initial' ? (
                                              <Fragment>
                                                {
                                                  inventoryType === 'fba' && (
                                                    <Button className="ml-2" onClick={onClickSyncOrder} disabled={!isHold}>
                                                      出荷指示を更新する
                                                    </Button>
                                                  )
                                                }
                                                <Button color="success" className="ml-2" onClick={toggleStatus.bind(null, order, 'processed')}>
                                                  処理済みにする
                                                </Button>
                                                <Button color="warning" className="ml-2" onClick={toggleStatus.bind(null, order, 'cancelled')}>
                                                  キャンセル済みにする
                                                </Button>
                                              </Fragment>
                                            ) : (
                                              <Button color="warning" onClick={toggleStatus.bind(null, order, 'initial')}>
                                                戻す
                                              </Button>
                                            )
                                          }
                                          <EditButton className="ml-2" itemRef={order.ref} FormModal={ModelFormModal} formProps={{ title: '注文 編集', fields: orderAdminFields, }} />
                                          <DeleteButton
                                            className="ml-2"
                                            itemRef={order.ref}
                                            confirmMessage={
                                              order.FulfillmentOrder != null ? (
                                                'FBA出荷指示がすでに作られています。削除して問題なければOKを押してください'
                                              ) : order.logispShippingRequest != null ? (
                                                'LOGISP出荷依頼がすでに作られています。削除して問題なければOKを押してください'
                                              ) : undefined
                                            }
                                            onFinish={onDeleted}
                                          />
                                          {
                                            inventoryType === 'fba' && (
                                              <Fragment>
                                                <ProgressButton className="ml-2" process={onClickCreateAmazonOrder.bind(null, false, 'Standard')} disabled={order.FulfillmentOrder != null}>
                                                  通常配送で出荷指示作成
                                                </ProgressButton>
                                                <ProgressButton className="ml-2" process={onClickCreateAmazonOrder.bind(null, true, 'Standard')} disabled={!['FAILED', 'CANCELLED', 'RECEIVED', 'UNFULFILLABLE'].includes(order.amazonStatus) || isShip}>
                                                  通常配送で出荷指示再作成
                                                </ProgressButton>
                                                <ProgressButton className="ml-2" process={onClickCreateAmazonOrder.bind(null, false)} disabled={order.FulfillmentOrder != null}>
                                                  出荷指示作成
                                                </ProgressButton>
                                                <ProgressButton className="ml-2" process={onClickCreateAmazonOrder.bind(null, true)} disabled={!['FAILED', 'CANCELLED', 'RECEIVED', 'UNFULFILLABLE'].includes(order.amazonStatus) || isShip}>
                                                  出荷指示再作成
                                                </ProgressButton>
                                                <ProgressButton className="ml-2" process={onClickPullAmazonOrder.bind(null, null)}>
                                                  出荷指示取得
                                                </ProgressButton>
                                              </Fragment>
                                            )
                                          }
                                          {
                                            inventoryType === 'hanropluslogi' && (
                                              <Fragment>
                                                <ProgressButton className="ml-2" process={onClickCreateLogispShippingRequest} disabled={order.logispShippingRequest != null || order.logispStatus === 'initial'}>
                                                  LOGISP出荷依頼作成
                                                </ProgressButton>
                                              </Fragment>
                                            )
                                          }
                                          <Button className="ml-2" onClick={onClickShowDetail}>
                                            モール注文情報を確認
                                          </Button>
                                        </td>
                                      </Fragment>
                                    )
                                  }
                                </tr>
                              );
                            })
                          }
                        </Fragment>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                注文はありません
              </div>
            )
          }
        </div>
      </div>
      {showsOrderDetailModal && <OrderDetailModal order={targetOrder} isOpen onClickClose={toggleOrderDetailModal.bind(null, false)} />}
    </div>
  );
});

function OrderSyncButton(props) {
  const { expander, label, pull, ...extraProps } = props;
  const [isProcessing, toggleProcessing] = useToggle(false);
  const onClick = async () => {
    if(!window.confirm(`${label}します。`)) return;

    toggleProcessing(true);
    try {
      const { data }  = await pull({ expanderId: expander.id });
      toast.success('取得しました');
      console.error(get(data, 'error'));
    } catch(e) {
      toast.error('失敗しました');
    }
    toggleProcessing(false);
  };

  return (
    <Button disabled={isProcessing} onClick={onClick} {...extraProps}>
      <span className={classnames('fas fa-sync mr-1', { 'fa-spin': isProcessing })} />
      {label}
    </Button>
  );
}
