const { formatISO, } = require('date-fns');
const { sortBy, flatten, get, } = require('lodash');

const adminFields = () => {
  return {
    adminNote: {
      type: 'text',
      label: '注文memo',
    },
  };
};

const firstShippedOrders = (orders, startOn, endOn) => {
  return orders
    .filter((order) => {
      const { inventoryType = 'fba', } = order;
      return ({
        fba: _ => {
          const shipments = flatten([get(order, 'FulfillmentShipment.member', [])]).filter(_ => _);
          const [firstShipment] = sortBy(shipments.map(_ => get(_, 'ShippingDateTime')), _ => _);
          return firstShipment != null && startOn <= new Date(firstShipment) && (endOn == null || new Date(firstShipment) <= endOn);
        },
        hanropluslogi: _ => {
          const shippedAt = order.logispShippingRequest?.shippingItems?.map(_ => _.shippedOn != null && new Date(_.shippedOn)).filter(_ => _)[0];
          return shippedAt != null && startOn <= shippedAt && (endOn == null || shippedAt <= endOn);
        },
      })[inventoryType]();
    });
};

module.exports = {
  statuses: {
    initial: '未処理',
    processed: '処理済み',
    cancelled: 'キャンセル済み',
  },
  firstShippedOrders,
  adminFields,
};
