import React, { Fragment } from 'react';
import { get, isEmpty, orderBy, keyBy, } from 'lodash';
import { format as formatDate, startOfDay, endOfDay, } from 'date-fns';
import numeral from 'numeral';
import classnames from 'classnames';

import firebase from '../../firebase';
import { malls, inventoryTypes, } from '../../shared/config';
import { yen, ordersToRows, } from '../../util';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import Page from '../hocs/Page';
import QuerySelector from '../QuerySelector';
import QueryInput from '../QueryInput';
import QueryBoolean from '../QueryBoolean';
import DateSelector from '../DateSelector';
import ExportButton from '../ExportButton';

const { entries, } = Object;
const db = firebase.firestore();
const ordersRef = db.collection('orders');
const mallOptions = entries(malls).map(([k, _]) => ({ value: k, label: _.label }));

export default Page(function Orders (props) {
  const { tenant } = props;
  const {
    orderedAtStartOn: orderedAtStartOnString,
    orderedAtEndOn: orderedAtEndOnString,
    shippedAtStartOn: shippedAtStartOnString,
    shippedAtEndOn: shippedAtEndOnString,
    malls: mallsForFilter,
    sourceOrderId: sourceOrderIdForFilter,
    sku: skuForFilter,
    asin: asinForFilter,
    title: titleForFilter,
  } = useQueryParams();
  const orderedAtStartOn = orderedAtStartOnString && new Date(orderedAtStartOnString);
  const orderedAtEndOn = orderedAtEndOnString && new Date(orderedAtEndOnString);
  const shippedAtStartOn = shippedAtStartOnString && new Date(shippedAtStartOnString);
  const shippedAtEndOn = shippedAtEndOnString && new Date(shippedAtEndOnString);
  const productsRef = tenant.ref.collection('products');
  const products = useCollectionSubscription(productsRef, [tenant.id]);
  const productsById = keyBy(products, 'id');
  const orders = useCollectionSubscription(ordersRef.where('tenantId', '==', tenant.id), [tenant.id]);
  const sortedOrders = orderBy(orders, _ => _.createdAt.toDate(), 'desc');
  const rows = ordersToRows(sortedOrders, productsById);

  // NOTE: filter
  let filteredRows = rows;
  if(!isEmpty(mallsForFilter)) {
    filteredRows = filteredRows.filter(_ => mallsForFilter.includes(_.order.mall));
  }
  if(!isEmpty(sourceOrderIdForFilter)) {
    filteredRows = filteredRows.filter(_ => _.order.sourceOrderId === sourceOrderIdForFilter);
  }
  if(!isEmpty(skuForFilter)) {
    filteredRows = filteredRows.filter(_ => _.items.some(_ => _.sku === skuForFilter));
  }
  if(!isEmpty(asinForFilter)) {
    filteredRows = filteredRows.filter(_ => _.items.some(_ => _.asin === asinForFilter));
  }
  if(!isEmpty(titleForFilter)) {
    filteredRows = filteredRows.filter(_ => _.items.some(_ => _.title.includes(titleForFilter)));
  }
  if(orderedAtStartOn != null) {
    filteredRows = filteredRows.filter(_ => _.order.orderedAt.toDate() >= startOfDay(orderedAtStartOn));
  }
  if(orderedAtEndOn != null) {
    filteredRows = filteredRows.filter(_ => _.order.orderedAt.toDate() <= endOfDay(orderedAtEndOn));
  }
  if(shippedAtStartOn != null) {
    filteredRows = filteredRows.filter(_ => _.shipments.some(_ => _.ShippingDateTime && new Date(_.ShippingDateTime) >= startOfDay(shippedAtStartOn)))
  }
  if(shippedAtEndOn != null) {
    filteredRows = filteredRows.filter(_ => _.shipments.some(_ => _.ShippingDateTime && new Date(_.ShippingDateTime) <= endOfDay(shippedAtEndOn)))
  }
  const rowsForExport = _ => filteredRows.map((row) => {
    const {
      items,
      order: { id, sourceOrderId, orderedAt, mall, amazonStatus, },
      shipments,
    } = row;
    return items.map((item) => {
      const { itemId, quantity, unitPrice, netPrice, sku, title, imageUrl, itemUrl, product, cost = 0, fbaShipmentFee = 0, } = item;
      return {
        sourceOrderId,
        orderedAt: formatDate(orderedAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        sku,
        itemId,
        itemTitle: title,
        netPrice,
        quantity,
        amount: netPrice * quantity,
        fbaShipmentFee: fbaShipmentFee * quantity,
        cost: cost * quantity,
        profit: (netPrice - fbaShipmentFee - cost) * quantity,
        amazonStatus,
        shippedAt: shipments.map(_ => formatDate(new Date(_.ShippingDateTime), 'yyyy/MM/dd HH:mm')).join(','),
        mall,
      };
    });
  }).flat();

  return (
    <div className="orders container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>注文一覧</h4>
        </div>
        <div className="d-flex justify-content-between align-items-end mb-2">
          <div className="d-flex flex-column gap-1">
            <div className="d-flex align-items-end flex-wrap gap-1">
              <QuerySelector isMulti width={200} paramName="malls" options={mallOptions} label="モールで絞込み" />
              <QueryInput paramName="sourceOrderId" label="注文番号で検索" />
              <QueryInput paramName="sku" label="SKUで検索" />
              <QueryInput paramName="asin" label="ASINで検索" />
              <QueryInput paramName="title" label="商品名で検索(部分一致)" />
            </div>
            <div className="d-flex align-items-end flex-wrap gap-1">
              <DateSelector paramName="orderedAtStartOn" label="注文日(開始)" />
              <DateSelector paramName="orderedAtEndOn" label="注文日(終了)" invalid={orderedAtStartOn > orderedAtEndOn} />
              <DateSelector paramName="shippedAtStartOn" label="出荷日(開始)" />
              <DateSelector paramName="shippedAtEndOn" label="出荷日(終了)" invalid={shippedAtStartOn > shippedAtEndOn} />
            </div>
          </div>
          <div className="d-flex text-nowrap gap-1">
            <ExportButton fileName="orders.csv" rows={rowsForExport} />
          </div>
        </div>
        <div className="mt-3 overflow-scroll position-relative" style={{ zIndex: 0 }}>
          {
            orders.length > 0 ? (
              <table className="table sticky-table">
                <thead className="thead-light text-center">
                  <tr className="text-nowrap">
                    <th>注文番号</th>
                    <th>注文日時</th>
                    <th>画像</th>
                    <th style={{ minWidth: 100 }}>SKU</th>
                    <th style={{ minWidth: 150 }}>商品名</th>
                    <th>配送元</th>
                    <th>受取単価</th>
                    <th>個数</th>
                    <th>合計金額</th>
                    <th>配送料</th>
                    <th>仕入額</th>
                    <th>利益額</th>
                    <th>出荷状態</th>
                    <th>出荷日時</th>
                    <th>モール</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredRows.map((row) => {
                      const {
                        items,
                        order,
                        order: { id, orderedAt, totalPrice, mall, amazonStatus, status, sourceOrderId, inventoryType = 'fba', },
                        tenant,
                        shipments,
                      } = row;
                      const isCancelled = status === 'cancelled';

                      return (
                        <Fragment key={id}>
                          {
                            items.map((item, i) => {
                              const { sku, quantity, title, itemId, netPrice, imageUrl, itemUrl, product, fbaShipmentFee = 0, cost = 0, } = item;
                              const profit = netPrice - cost - fbaShipmentFee;

                              return (
                                <tr key={itemId} className={classnames({ 'table-secondary': isCancelled })} data-id={id}>
                                  {
                                    i === 0 && (
                                      <Fragment>
                                        <td rowSpan={items.length}>
                                          {sourceOrderId}
                                        </td>
                                        <td rowSpan={items.length}>
                                          {formatDate(orderedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                                        </td>
                                      </Fragment>
                                    )
                                  }
                                  <td>
                                    <img src={imageUrl} style={{ maxWidth: 80 }} />
                                  </td>
                                  <td className="text-break">
                                    {sku}
                                  </td>
                                  <td className="text-break">
                                    {
                                      title != null ? (
                                        <a href={itemUrl} target="_blank">
                                          {title}
                                        </a>
                                      ) : (
                                        <span className="small text-muted">[削除された商品]</span>
                                      )
                                    }
                                  </td>
                                  <td>
                                    {inventoryTypes[inventoryType]?.label}
                                  </td>
                                  <td className="text-right">
                                    {yen(netPrice)}
                                  </td>
                                  <td className="text-right">
                                    {numeral(quantity).format('0,0')}
                                  </td>
                                  <td className="text-right">
                                    {yen(netPrice * quantity)}
                                  </td>
                                  <td className="text-right">
                                    {fbaShipmentFee ? yen(fbaShipmentFee * quantity) : '-'}
                                  </td>
                                  <td className="text-right">
                                    {cost ? yen(cost * quantity) : '-'}
                                  </td>
                                  <td className="text-right">
                                    {profit ? yen(profit * quantity) : '-'}
                                  </td>
                                  {
                                    i === 0 && (
                                      <Fragment>
                                        <td className="text-right" rowSpan={items.length}>
                                          {isCancelled ? 'キャンセル' : amazonStatus}
                                        </td>
                                        <td>
                                          {shipments.map(_ => _.ShippingDateTime != null ? formatDate(new Date(_.ShippingDateTime), 'yyyy/MM/dd HH:mm') : '').join(',')}
                                        </td>
                                        <td className="text-nowrap" rowSpan={items.length}>
                                          {mall}
                                        </td>
                                      </Fragment>
                                    )
                                  }
                                </tr>
                              );
                            })
                          }
                        </Fragment>
                      );
                    })
                  }
                </tbody>
 
              </table>
            ) : (
              <div>
                注文はまだありません
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});
