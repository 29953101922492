import React, { useEffect, Fragment, } from 'react';
import { chunk, get, pick, isEmpty } from 'lodash';
import { format as formatDate, } from 'date-fns';
import { toast } from 'react-toastify';

import env from '../../env';
import firebase, { functions } from '../../firebase';
import { malls } from '../../shared/config';
import { fields } from '../../shared/models/shop';
import { batch, getCollectionData, getAllCollectionDataByChunk, } from '../../shared/firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import ModelFormModal from '../modals/ModelFormModal';
import ExpanderPage from '../hocs/ExpanderPage';
import ModalButton from '../ModalButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import AddButton from '../AddButton';
import ProgressButton from '../ProgressButton';
import SettingsTabs from '../SettingsTabs';
import FilesUploadButton from '../FilesUploadButton';
import ExportButton from '../ExportButton';

const db = firebase.firestore();
const saveRakutenVariantIds = functions.httpsCallable('saveRakutenVariantIds', { timeout: 550000 });
const saveRakutenProducts = functions.httpsCallable('saveRakutenProducts', { timeout: 550000 });
const clearRakutenFiles = functions.httpsCallable('clearRakutenFiles', { timeout: 550000 });
const validateRakutenProductStatuses = functions.httpsCallable('validateRakutenProductStatuses', { timeout: 550000 });
const validateRakutenProductStatuses2 = functions.httpsCallable('validateRakutenProductStatuses2', { timeout: 550000 });
const validateMercariProductStatuses = functions.httpsCallable('validateMercariProductStatuses', { timeout: 550000 });
const validateMercariProductStatuses2 = functions.httpsCallable('validateMercariProductStatuses2', { timeout: 550000 });
const pullMercariProducts = functions.httpsCallable('pullMercariProducts', { timeout: 550000 });
const pullRakutenFolders = functions.httpsCallable('pullRakutenFolders', { timeout: 550000 });
const pullRakutenCategories = functions.httpsCallable('pullRakutenCategories', { timeout: 550000 });
const updateMercariProducts = functions.httpsCallable('updateMercariProducts', { timeout: 550000 });
const storageRef = firebase.storage().ref();
const { entries } = Object;

export default ExpanderPage(function ExpanderSettings (props) {
  const { user, expander } = props;
  const shops = useCollectionSubscription(expander.ref.collection('shops').orderBy('createdAt'), [expander]);

  return (
    <div className="expander-settings container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>設定</h4>
        </div>
        <div className="mt-4">
          <div className="d-flex justify-content-end mb-3">
            <AddButton itemRef={expander.ref.collection('shops').doc()} FormModal={ModelFormModal} formProps={{ title: 'モール店舗 追加', fields, }} />
          </div>
          <div className="overflow-scroll">
            <table className="table sticky-table">
              <thead className="thead-light text-center">
                <tr className="text-nowrap">
                  <th>ID</th>
                  <th>モール</th>
                  <th>名称</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  shops.map((shop) => {
                    const { id, ref, mall, name, authorizedAt, } = shop;
                    const onClickMappingRakutenVariantIds = async () => {
                      if(!window.confirm('本当にマッピングしますか？(出品数1万点につき1,2分程度かかります')) return;

                      try {
                        await saveRakutenVariantIds({ expanderId: expander.id, shopId: id });
                        toast.success(`マッピングしました`, { autoClose: false });
                      } catch(e) {
                        console.error(e.message);
                        toast.error(`失敗しました。${e.message}`, { autoClose: true });
                      }
                    };
                    const onClickValidateRakutenProductStatuses = async () => {
                      if(!window.confirm('ハンロプラスと楽天側の出品ステータスについて確認します。出品JOBを回していると整合性が一部取れない可能性があります。よろしいですか？')) return;

                      try {
                        await (async function saveAllRakutenProducts(cursorMark) {
                          const { data: { nextCursorMark } } = await saveRakutenProducts({ expanderId: expander.id, shopId: id, cursorMark, });
                          nextCursorMark && await saveAllRakutenProducts(nextCursorMark);
                        })();
                        await (async function validateAllRakutenProductStatuses(index) {
                          const { data: { url, nextIndex } } = await validateRakutenProductStatuses({ expanderId: expander.id, shopId: id, index, });
                          window.open(url);
                          nextIndex && await validateAllRakutenProductStatuses(nextIndex);
                        })();
                        await (async function validateAllRakutenProductStatuses2(index) {
                          const { data: { url, nextIndex } } = await validateRakutenProductStatuses2({ expanderId: expander.id, shopId: id, index, });
                          window.open(url);
                          nextIndex && await validateAllRakutenProductStatuses2(nextIndex);
                        })();
                        toast.success(`ハンロプラスと楽天側の出品ステータスについて確認しました`, { autoClose: false });
                      } catch(e) {
                        console.error(e.message);
                        toast.error(`失敗しました。${e.message}`, { autoClose: true });
                      }
                    };
                    const onClickClearRakutenFiles = async () => {
                      if(!window.confirm('楽天の不要な画像を削除します。出品JOBを回していると整合性が一部取れない可能性があります。よろしいですか？')) return;

                      try {
                        await (async function saveAllRakutenProducts(cursorMark) {
                          const { data: { nextCursorMark } } = await saveRakutenProducts({ expanderId: expander.id, shopId: id, cursorMark, });
                          nextCursorMark && await saveAllRakutenProducts(nextCursorMark);
                        })();
                        await (async function clearAllRakutenFiles(offset) {
                          const { data: { nextOffset } } = await clearRakutenFiles({ expanderId: expander.id, shopId: id, offset, });
                          nextOffset && await clearAllRakutenFiles(nextOffset);
                        })(1);
                        toast.success(`楽天の不要な画像を削除しました`, { autoClose: false });
                      } catch(e) {
                        console.error(e.message);
                        toast.error(`失敗しました。${e.message}`, { autoClose: true });
                      }
                    };
                    const onClickClearRakutenFolders = async () => {
                      if(!window.confirm('楽天のフォルダ情報をクリアします（楽天側でフォルダを削除した場合に要実行）。よろしいですか？')) return;
                      if(!window.confirm('楽天側にh+のフォルダが存在するのにこれを実行した場合、出品がうまくいかなくなります。よろしいですか？')) return;

                      try {
                        await batch(db, await getAllCollectionDataByChunk(shop.ref.collection('rakutenFolderNumbers')), (b, _) => b.delete(_.ref));
                        await shop.ref.update({ rakutenFolderIds: { } });
                        toast.success(`楽天のフォルダ情報をクリアしました`, { autoClose: false });
                      } catch(e) {
                        console.error(e.message);
                        toast.error(`失敗しました。${e.message}`, { autoClose: true });
                      }
                    };
                    const onClickPullRakutenFolders = async () => {
                      if(!window.confirm('楽天のフォルダ情報をh+に同期します')) return;

                      try {
                        await pullRakutenFolders({ expanderId: expander.id, shopId: shop.id, });
                        toast.success(`楽天のフォルダ情報を同期しました`, { autoClose: false });
                      } catch(e) {
                        console.error(e.message);
                        toast.error(`失敗しました。${e.message}`, { autoClose: true });
                      }
                    };
                    const onClickPullRakutenCategories = async () => {
                      if(!window.confirm('楽天のカテゴリを同期します。よろしいですか？')) return;

                      try {
                        await pullRakutenCategories({ expanderId: expander.id, shopId: id });
                        toast.success(`楽天のカテゴリを同期しました`, { autoClose: false });
                      } catch(e) {
                        console.error(e.message);
                        toast.error(`失敗しました。${e.message}`, { autoClose: true });
                      }
                    };
                    const onClickValidateMercariProductStatuses = async (mall) => {
                      if(!window.confirm('ハンロプラスとメルカリ側の出品ステータスについて確認します。出品JOBを回していると整合性が一部取れない可能性があります。よろしいですか？')) return;

                      try {
                        await (async function pullAllMercariProducts(cursor) {
                          const { data: { endCursor } } = await pullMercariProducts({ expanderId: expander.id, shopId: id, cursor, });
                          endCursor && await pullAllMercariProducts(endCursor);
                        })();
                        await (async function validateAllMercariProductStatuses(index) {
                          const { data: { url, nextIndex } } = await validateMercariProductStatuses({ expanderId: expander.id, shopId: id, index, });
                          window.open(url);
                          nextIndex && await validateAllMercariProductStatuses(nextIndex);
                        })();
                        await (async function validateAllMercariProductStatuses2(index) {
                          const { data: { url, nextIndex } } = await validateMercariProductStatuses2({ expanderId: expander.id, shopId: id, index, });
                          window.open(url);
                          nextIndex && await validateAllMercariProductStatuses2(nextIndex);
                        })();
                        toast.success(`ハンロプラスとメルカリ側の出品ステータスについて確認しました`, { autoClose: false });
                      } catch(e) {
                        console.error(e.message);
                        toast.error(`失敗しました。${e.message}`, { autoClose: true });
                      }
                    };
                    const mercariRowsForExport = async () => {
                      await (async function pullAllMercariProducts(cursor) {
                        const { data: { endCursor } } = await pullMercariProducts({ expanderId: expander.id, shopId: id, cursor, });
                        endCursor && await pullAllMercariProducts(endCursor);
                      })();
                      const mercariProductChunks = await getCollectionData(ref.collection('mercariProductChunks'));
                      const mercariProducts = mercariProductChunks.flatMap(_ => _.data);
                      return mercariProducts.map((mercariProduct) => {
                        const { name, sku, } = mercariProduct;
                        return {
                          name,
                          sku,
                        };
                      });
                    };
                    const onUploaded = async ([{ file, path, downloadUrl }]) => {
                      await shop.ref.update({
                        registerSuffixImage: {
                          uploadedAt: new Date(),
                          downloadUrl,
                          storagePath: path,
                          ...pick(file, ['name', 'type']),
                          accepted: null,
                        },
                      });
                    };
                    const onSubmitBatchUpdateMercariShippingDurations = async (values) => {
                      if(!window.confirm('メルカリ側の発送納期を一括で変更します。よろしいですか？')) return;

                      await (async function pullAllMercariProducts(cursor) {
                        const { data: { endCursor } } = await pullMercariProducts({ expanderId: expander.id, shopId: id, cursor, });
                        endCursor && await pullAllMercariProducts(endCursor);
                      })();
                      const mercariProductChunks = await getCollectionData(ref.collection('mercariProductChunks'));
                      const mercariProducts = mercariProductChunks.flatMap(_ => _.data);
                      const errors = await Promise.all(chunk(mercariProducts, 20).map(async (mercariProducts, i) => {
                        await new Promise(_ => setTimeout(_, 1000 * i));
                        try {
                          await updateMercariProducts({
                            expanderId: expander.id,
                            shopId: id,
                            items: mercariProducts.map(_ => ({ id: _.id, ...values, })),
                          });
                        } catch (e) {
                          console.error(e);
                          return e;
                        }
                      }));
                      if(errors.every(_ => _ == null)) {
                        toast.success(`メルカリ側の発送納期を一括で変更しました`, { autoClose: false });
                      } else {
                        toast.error(`失敗しました。`, { autoClose: false });
                      }
                    };

                    return (
                      <tr key={id}>
                        <td>
                          {id}
                          {
                            user.dev && (
                              <a className="d-block" href={`https://console.firebase.google.com/u/0/project/hanro-plus/firestore/data/~2Fexpanders~2F${expander.id}~2Fshops~2F${id}`} target="_blank">
                                F
                              </a>
                            )
                          }
                        </td>
                        <td>
                          {malls[mall]?.label}
                        </td>
                        <td>
                          {name}
                        </td>
                        <td>
                          {
                            ({
                              rakuten: shop.rakutenShopUrl,
                              yahoo: shop.yahooSellerId,
                            })[mall]
                          }
                        </td>
                        <td>
                          <div className="d-flex flex-wrap gap-1 align-items-start justify-content-end">
                            {
                              mall === 'yahoo' && (
                                <span>
                                  <a href={`${env('CLOUD_FUNCTIONS_ENDPOINT')}/authYahoo?expanderId=${expander.id}&shopId=${id}`} className="btn btn-primary" target="_blank">
                                    Yahoo認証
                                    <span className="fas fa-external-link-alt ml-1" />
                                  </a>
                                  {
                                    authorizedAt && (
                                      <span className="text-muted small">{formatDate(authorizedAt.toDate(), 'yyyy/MM/dd HH:mm')}認証</span>
                                    )
                                  }
                                </span>
                              )
                            }
                            {
                              mall === 'rakuten' && (
                                <Fragment>
                                  <ProgressButton process={onClickMappingRakutenVariantIds}>
                                    variantIdマッピング
                                  </ProgressButton>
                                  <ProgressButton process={onClickValidateRakutenProductStatuses}>
                                    ハンロプラスと楽天の出品ステータス確認
                                  </ProgressButton>
                                  <ProgressButton color="danger" process={onClickClearRakutenFiles}>
                                    不要な画像を削除
                                  </ProgressButton>
                                  <ProgressButton color="danger" process={onClickClearRakutenFolders}>
                                    フォルダ情報をクリアする
                                  </ProgressButton>
                                  <ProgressButton process={onClickPullRakutenFolders}>
                                    フォルダ情報同期
                                  </ProgressButton>
                                  <ProgressButton process={onClickPullRakutenCategories}>
                                    楽天カテゴリ同期
                                  </ProgressButton>
                                  {
                                    shop.registerSuffixImage != null && (
                                      <a href={shop.registerSuffixImage.downloadUrl} target="_blank">
                                        <img src={shop.registerSuffixImage.downloadUrl} style={{ maxWidth: 50, maxHeight: 30 }} />
                                      </a>
                                    )
                                  }
                                  <FilesUploadButton label="出品時固定画像" multiple={false} accept="image/*" storagePath={`expanders/${expander.id}/shops/${shop.id}/registerSuffixImage`} onUploaded={onUploaded} />
                                </Fragment>
                              )
                            }
                            {
                              mall === 'mercari' && (
                                <Fragment>
                                  <ExportButton label="メルカリ商品" fileName="mercari_products.csv" rows={mercariRowsForExport} />
                                  <ModalButton
                                    label="発送納期一括変更"
                                    Modal={ModelFormModal}
                                    modalProps={{
                                      title: '発送納期一括変更',
                                      fields: {
                                        shippingDuration: {
                                          type: 'select',
                                          options: entries({
                                            ONE_TO_TWO_DAYS: { label: '１〜２日で発送', },
                                            TWO_TO_THREE_DAYS: { label: '２〜３日で発送', },
                                            FOUR_TO_SEVEN_DAYS: { label: '４〜７日で発送', },
                                            EIGHT_TO_FOURTEEN_DAYS: { label: '８〜１４日で発送', },
                                            EIGHT_DAYS_OR_MORE_OR_UNDECIDED: { label: '９０日以内で発送', },
                                          }).map(([k, v]) => ({ label: v.label, value: k })),
                                        },
                                      },
                                      onSubmit: onSubmitBatchUpdateMercariShippingDurations,
                                      submitLabel: '一括変更する',
                                    }}
                                  />
                                  <ProgressButton process={onClickValidateMercariProductStatuses}>
                                    ハンロプラスとメルカリの出品ステータス確認
                                  </ProgressButton>
                                  {
                                    shop.registerSuffixImage != null && (
                                      <a href={shop.registerSuffixImage.downloadUrl} target="_blank">
                                        <img src={shop.registerSuffixImage.downloadUrl} style={{ maxWidth: 50, maxHeight: 30 }} />
                                      </a>
                                    )
                                  }
                                  <FilesUploadButton label="出品時固定画像" multiple={false} accept="image/*" storagePath={`expanders/${expander.id}/shops/${shop.id}/registerSuffixImage`} onUploaded={onUploaded} />
                                </Fragment>
                              )
                            }
                            <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '店舗 編集', fields: fields(), }} />
                            <DeleteButton itemRef={ref} disabled />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});
