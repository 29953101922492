import React, { useMemo, useEffect, useState } from 'react';
import { Form, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { pick, uniqBy, } from 'lodash';

import firebase, { functions } from '../firebase';

const { round } = Math;
const updateMallPrices = functions.httpsCallable('updateMallPrices', { timeout: 550000 });

export default function ProductPriceEditor(props) {
  const { user, inventoryType = 'fba', type = 'amount', tenant, items, onFinish, disabled = false, ...extraProps } = props;
  const netPriceName = ({ fba: 'netPrice', hanropluslogi: 'hanropluslogiNetPrice', })[inventoryType];
  const quantityName = ({ fba: 'quantity', hanropluslogi: 'hanropluslogiQuantity', })[inventoryType];
  const originalNetPrice = items[0]?.[netPriceName] ?? items[0]?.netPrice;
  const initialValue = useMemo(_ => props.batch ? null : originalNetPrice, [props.batch, originalNetPrice]);
  const productsRef = tenant.ref.collection('products');
  const amazonProductsRef = tenant.ref.collection('amazonProducts');
  const [editingValue, setValue] = useState(initialValue);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const onSubmit = async () => {
    const message = [
      '本当に更新しますか？',
      type === 'profitRate' && '(仕入値が未設定もしくはFBA配送料が未取得のものは処理されません)',
    ].filter(_ => _).join('');
    if(!window.confirm(message)) return;

    toggleSubmitting(true);
    try {
      const value = parseFloat(editingValue, 10);
      const data = items.map((item) => {
        const netPrice = ({
          amount: value,
          rate: round(parseInt(item.amazonProduct.Offers.Offer.BuyingPrice.ListingPrice.Amount, 10) * (value / 100)),
          profitRate: round((parseInt(item.cost, 10) + parseInt(item.conclusiveFbaShipmentFee, 10)) / (1 - (value / 100))),
        })[type];
        return { item, netPrice };
      });
      if(data.some(_ => _.netPrice < 300)) {
        window.alert('卸値が300円未満になるものがあり、処理されませんでした');
        toggleSubmitting(false);
        onFinish && await onFinish();
        return;
      }

      await data.reduce(async (x, { item, netPrice, }) => {
        await x;

        const originalNetPrice = item[netPriceName] ?? item.netPrice;
        const { isVariationsProduct, variationParentAsin, id, } = item;
        const itemId = isVariationsProduct ? `${tenant.keyForMall}--${variationParentAsin.toLowerCase()}` : id;
        if(({
          fba: (item.hanropluslogiQuantity ?? 0) === 0,
          hanropluslogi: item.hanropluslogiQuantity > 0,
        })[inventoryType]) {
          const res = await updateMallPrices({ tenantId: tenant.id, productId: id, itemId, originalPrice: round(originalNetPrice * 1.3), price: round(netPrice * 1.3), quantity: item[quantityName], });
          if(res.data.status === 'NG') throw pick(res.data, ['errorExpander', 'errorShop', 'errorMessage']);
        }

        await item.ref.update({
          [netPriceName]: netPrice,
          updatedAt: new Date(),
          ...(
            !user.dev && {
              [`${netPriceName}Logs`]: firebase.firestore.FieldValue.arrayUnion({
                savedAt: new Date(), netPrice, savedBy: pick(user, ['id', 'email', 'displayName']),
              }),
            }
          ),
        });
      }, Promise.resolve());
      toast.success('保存しました');
    } catch(error) {
      toast.error('失敗しました');
      if(user.admin && error.errorShop) {
        toast.error(`[${error.errorExpander?.name}] [${error.errorShop.name}] ${error.errorMessage}`);
      }
      console.error(error);
    }
    toggleSubmitting(false);
    onFinish && await onFinish();
  };
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Form onSubmit={e => e.preventDefault() || onSubmit()} {...extraProps}>
      <Input type="number" className="text-right" value={editingValue} onChange={_ => setValue(_.target.value)} onBlur={_ => setValue(initialValue)} disabled={disabled || isSubmitting} />
    </Form>
  );
};
