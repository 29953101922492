import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Navbar, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import env from '../env';
import logo from '../logo.png';
import firebase from '../firebase';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import { basicSettingsStatus } from '../shared/models/tenant';

const auth = firebase.auth();

export default function HeaderNav (props) {
  const { user, tenant, } = props;
  const onClickLogOut = async () => {
    if(!window.confirm('ログアウトしますか？')) return;
    await auth.signOut();
    window.location.reload();
  };
  const onClickPasswordReset = async () => {
    if(!window.confirm('パスワード変更のためのメールをお送りします。よろしいですか？')) return;
    await auth.sendPasswordResetEmail(user.email)
    toast.success('パスワードリセットのメールをお送りしました。');
  };

  return (
    <header className="header-nav">
      <Navbar color="light" light className="justify-content-between">
        <Link to="/">
          <img src={logo} style={{ height: 24 }} />
        </Link>
        <Nav navbar>
          {
            user != null && (
              <UncontrolledDropdown tag="div">
                <DropdownToggle nav caret>
                  {user.displayName}
                </DropdownToggle>
                <DropdownMenu right>
                  {
                    user.admin && (
                      <DropdownItem tag={Link} to="/admin">
                        管理画面
                      </DropdownItem>
                    )
                  }
                  <DropdownItem tag={Link} to="/mypage/profile">
                    アカウント情報
                  </DropdownItem>
                  <DropdownItem onClick={onClickPasswordReset}>
                    パスワード変更
                  </DropdownItem>
                  <DropdownItem tag="a" href={env('SP_API_AUTH_CONSENT_URI')} className="d-flex flex-column align-items-stretch">
                    <div>SP API認証</div>
                    {tenant.spApi != null && <div className="badge badge-success">認証済み</div>}
                  </DropdownItem>
                  <DropdownItem onClick={onClickLogOut}>
                    ログアウト
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          }
        </Nav>
      </Navbar>
      {
        tenant != null && (
          <TenantBasicSettingsBar tenant={tenant} />
        )
      }
    </header>
  );
};

function TenantBasicSettingsBar (props) {
  const { tenant } = props;
  const products = useCollectionSubscription(tenant.ref.collection('products').limit(1), [tenant], { initialItems: null });
  const _basicSettingsStatus = basicSettingsStatus(tenant);

  return ({
    initial: (
      <div className="alert alert-danger py-2">
        <Link to="/settings/basic" className="text-danger">
          基本設定および出金設定が必要です
        </Link>
      </div>
    ),
    uploaded: (
      <div className="alert alert-info py-2">
        <Link to="/settings/basic" className="text-info">
          画像審査中です
        </Link>
      </div>
    ),
    rejected: (
      <div className="alert alert-danger py-2">
        <Link to="/settings/basic" className="text-danger">
          画像を確認しましたが、正しくありません。もう一度画像をアップロードしてください。
        </Link>
      </div>
    ),
    accepted: null,
  })[_basicSettingsStatus] ?? (
    products?.length === 0 ? (
      <div className="alert alert-danger py-2">
        <Link to="/products" className="text-danger">
          商品登録がされていません。商品管理右上の「新規登録」ボタンより商品登録を行なってください。
        </Link>
      </div>
    ) : null
  );
}
